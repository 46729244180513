import Service from "@/services/Service";
const baseurl = '/api/citologia/seccion';

export default {
    index(params = {}){
        return Service.get(`${baseurl}`, {
            params : params
        });
    }
}
